<template>
  <transition
    name="fade"
    @after-leave="$emit('close')"
  >
    <div
      v-if="visible"
      class="notification"
      :class="type"
    >
      <strong>{{ notification.libelle |capitalize }}</strong>
      <div v-if="notification.description">
        {{ notification.description }}
      </div>
      <div class="row">
        <div class="col-8"><small>{{ notification.createdAt|showTime }}</small></div>
        <div class="col-4 text-right">
          <button class="btn btn-secondary" @click="closeNotification">
            Close
          </button>
        </div>
      </div>
      <audio
        ref="audio"
        src="@/assets/audio/notification1.mp3"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: "Notification",
  props: {
    notification: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: "info"
    },
    duration: {
      type: Number,
      default: 10000 // 10 seconds
    }
  },
  data() {
    return {
      visible: true
    };
  },
  
  mounted() {
    this.startTimer();
    this.playSound();
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        this.closeNotification();
      }, this.duration);
    },
    closeNotification() {
      this.visible = false;
    },
    playSound() { this.$refs.audio.play(); }
  }
};
</script>

<style scoped>
.notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 8px;
  width: 350px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.notification.info {
  border-color: #007bff;
  background-color: #cce5ff;
}
.notification.error {
  border-color: #dc3545;
  background-color: #f8d7da;
}
.notification.delete_bill {
  border-color: #ffc107;
  background-color: #fff3cd;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  margin-left: 10px;
}
</style>
