<template>
  <nav id="sidebar">
    <ul
      class="list-unstyled menu-categories"
      id="accordionExample"
    >
      <li class="menu">
        <router-link
          :to="{name: 'home'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-home" />
            <span>Tableau de bord</span>
          </div>
        </router-link>
      </li>
      <li
        class="menu"
        v-if="is_labo_user || is_technicien_imagerie || is_veto || is_admin || is_assistan_veto_major || is_assistan_veto"
      >
        <router-link
          :to="{name: 'users'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-user" />
            <span>Utilisateur</span>
          </div>
        </router-link>
      </li>
      <li
        class="menu group-managment"
        v-if="is_veto || is_admin || is_assistan_veto_major"
      >
        <router-link
          :to="{name: 'groups'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-users" />
            <span>Groupes utilisateurs</span>
          </div>
        </router-link>
      </li>
      <li class="menu-title">
        Identification
      </li>
      <identification />
      <li class="menu-title">
        Sanitaire
      </li>
      <sanitaire />
      
      <li class="menu-title">
        Packs
      </li>
      <Pack />
      <li class="menu-title">
        Garderie
      </li>
      
      <li class="menu-title">
        MarketPlace
      </li>
      <market-place />
      <li
        class="menu-title"
        v-if="is_veto || is_admin"
      >
        Messagerie
      </li>
      <li
        class="menu"
        v-if="is_admin"
      >
        <router-link
          :to="{name: 'chat'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="lab la-rocketchat" />
            <span>Chat</span>
          </div>
        </router-link>
      </li>

      <li
        class="menu"
        v-if="is_veto || is_admin"
      >
        <router-link
          :to="{name: 'direct_sms'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-sms" />
            <span>Campagne SMS</span>
          </div>
        </router-link>
      </li>

      <li
        class="menu-title"
        v-if="is_admin || is_veto || is_assistan_veto_major"
      >
        Administration
      </li>
      <administration v-if="is_admin || is_veto || is_assistan_veto_major" />
                    
      <li
        class="menu-title"
        v-if="is_super_admin"
      >
        Others
      </li>
      <li
        class="menu"
        v-if="is_super_admin"
      >
        <a
          href="#"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-file-code" />
            <span>Documentation</span>
          </div>
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
import {mapGetters} from 'vuex'
import Sanitaire from './menu/sanitaire.vue'
import Administration from './menu/admin.vue'
import Identification from './menu/identification.vue'
import MarketPlace from './menu/market.vue'
import Pack from './menu/pack.vue'
export default {
    setup() {
        
    },
    components: {Sanitaire, Administration, Identification, MarketPlace, Pack},
    computed: {
        ...mapGetters({
            is_admin: 'auth/is_admin',
            is_veto: 'auth/is_veterinaire',
            is_super_admin: 'auth/is_super_admin',
            is_assistan_veto: 'auth/is_assistant_veterinaire',
            is_assistan_veto_major: 'auth/is_assistant_veto_major',
            is_labo_user: 'auth/is_labo_user',
            is_technicien_imagerie: 'auth/is_technicien_imagerie'
        })
    }
}
</script>

<style scoped>
.menu-title{
  background-color: #fff !important;
  border: solid 1px #1c6100;
}
#sidebar ul.menu-categories.ps{
  padding-right: 0px !important;
}
</style>