import gql from 'graphql-tag'

export const ADD_TYPE_EVENT = gql`
mutation Mutation($type: TypeEventInput!){
    addTypeEvent(type: $type)
}
`
export const UPDATE_TYPE_EVENT = gql`
  mutation Mutation($uid: String!, $type: TypeEventInput!){
    updateTypeEvent(uid: $uid, type: $type)
  }
`
export const DELETE_TYPE_EVENT = gql`
mutation Mutation($uid: String!) {
    deleteTypeEvent(uid: $uid)
}
`

export const SUBSCRIBER_TYPE_EVENT = gql`
subscription Subscription {
  type_event {
    type
    data {
        uid
        libelle
        description
        label
        code
    }
  }
}
`

export const ADD_EVENT = gql`
mutation Mutation($event: EventInput!){
    addEvent(event: $event)
}
`
export const UPDATE_EVENT = gql`
  mutation Mutation($uid: String!, $event: EventInput!){
    updateEvent(uid: $uid, event: $event)
  }
`
export const ACCOMPLISHED_EVENT =  gql`
mutation Mutation($uid: String!, $accomplished: Boolean!){
  acccomplishedEvent(uid: $uid, accomplished: $accomplished)
}
`


export const DELETE_EVENT = gql`
mutation Mutation($uid: String!) {
    deleteEvent(uid: $uid)
}
`

export const SUBSCRIBER_EVENT = gql`
subscription Subscription {
  event {
    type
    data {
        uid
        type
        libelle
        allDay
        accomplished
        startDate
        endDate
        description
        infoUtile
        private
        createBy
        createdAt
    }
  }
}
`


export const SUBSCRIBER_NOTIFICATION = gql`
subscription Subscription ($uid: String!){
  notification (uid: $uid) {
    type
    data {
        uid
        type
        libelle
        description
        read
        destinataire
        files
        createdAt
    }
  }
}
`


